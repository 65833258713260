




import { Component, Vue } from 'vue-property-decorator';
import { store } from '@/store';
import { dispatchCheckLoggedIn } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';
import { readConstants } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';
import { apiUrl } from '@/env';

const startRouteGuard = async (to, from, next) => {
  await dispatchCheckLoggedIn(store);
  if (readIsLoggedIn(store)) {
    // when logged in
    if (to.path === '/') {
      localStorage.setItem('upt', 'refresh');
      next('home');
    } else if (to.path === '/login' || to.path === '/createUser' || to.path === '/recoverPassword' || to.path === '/register' || to.path === '/verify-email' || to.path === '/email-block') {
      next('/home');
    } else {
      next();
    }
  } else if (readIsLoggedIn(store) === false) {
    // when not logged in
    if (to.path === '/') {
      localStorage.setItem('upt', 'refresh');
      next('/home')
    } else if (to.path === '/login' || to.path === '/createUser' || to.path === '/recoverPassword' || to.path === '/home' || to.path === '/blank' || to.path === '/register' || to.path === '/verify-email' || to.path === '/email-block') {
      // open access function pages
      next();
    } else if (to.path === '/contact' || to.path === '/faq' || to.path === '/about' || to.path === '/ust' || to.path === '/bonus' || to.path === '/ftx-incident' || to.path === '/risk') {
      // open access pages
      next();
    // } else if (to.path === '/plan/usdt_fixed' || to.path === '/plan/usdt_stable' || to.path === '/plan/usdt_high' || to.path === '/plan/usdt_active') {
    //   // open access pages (plans)
    //   next();
    } else if (to.path.startsWith('/purchase') ||to.path.startsWith('/report') || to.path.startsWith('/news') || to.path.startsWith('/plan')|| to.path.startsWith('/how-to')) {
      // open access pages (reports)
      next();
    } else {
      next('/login');
    }
  }
};

@Component
export default class Start extends Vue {
  public beforeRouteEnter(to, from, next) {
    startRouteGuard(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    startRouteGuard(to, from, next);
  }
}
